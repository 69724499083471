@import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .paper {
    @apply bg-white py-6 rounded px-2 overflow-auto;
  }
  .new-prod-input {
    @apply border border-secondary rounded border-opacity-50 outline-none py-1 px-2 w-full max-w-sm;
  }
  .center-inner {
    @apply flex justify-center items-center;
  }
  .order-payment-item {
    @apply flex items-center justify-end gap-4 py-3 print:py-1 border-b border-secondary border-opacity-10;
  }
  .chip-primary {
    @apply bg-primary text-white px-1 rounded;
  }
  .ant-btn-custom {
    @apply flex items-center gap-2 justify-center text-secondary;
  }
  .btn {
    @apply px-2 py-1 rounded-sm border duration-300;
  }
  .btn-primary {
    @apply bg-primary border-primary text-white hover:text-primary hover:bg-opacity-0 hover:border-primary focus:bg-opacity-0 focus:border-primary focus:text-primary;
  }
  .btn-primary-f {
    @apply bg-primary border-primary text-white hover:text-primary hover:bg-opacity-0 hover:border-primary;
  }
  .btn-danger {
    @apply bg-danger border-danger text-white hover:text-danger hover:bg-opacity-0 hover:border-danger focus:bg-opacity-0 focus:border-danger focus:text-danger;
  }
  .btn-danger-f {
    @apply bg-danger border-danger text-white hover:text-danger hover:bg-opacity-0 hover:border-danger;
  }
  .btn-success {
    @apply bg-success border-success text-white hover:text-success hover:bg-opacity-0 hover:border-success focus:bg-opacity-0 focus:border-success focus:text-success;
  }
  .btn-success-f {
    @apply bg-success border-success text-white hover:text-success hover:bg-opacity-0 hover:border-success;
  }
  .action-btn-container {
    @apply flex items-center gap-1;
  }
  .action-btn {
    @apply text-xl bg-primary w-8 h-8 rounded-sm center-inner text-white hover:text-white hover:bg-opacity-75 duration-300 cursor-pointer;
  }
  .input__textArea > .ant-form-item-control,
  .input__textArea > .ant-form-item-control > .ant-form-item-control-input,
  .input__textArea > .ant-form-item-label {
    @apply !max-w-full !min-h-full;
  }
  .box-shadow-md {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
}

/* dragable table row style */
.row-dragging {
  background: white;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
/* /dragable table row style */

.ant-modal-wrap {
  z-index: 1050;
}
.ant-image-preview-img {
  display: initial;
}
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.width-full {
  appearance: textfield !important;
}
.ant-modal-footer > .ant-btn-primary {
  background-color: #f17e23;
  border-width: 0;
}
.ant-table-content {
  overflow-x: auto !important;
}
.ant-picker-ok > button {
  background-color: #40a9ff;
}

/* scroll bar start */
.history-scroll::-webkit-scrollbar {
  width: 8px;
}
.history-scroll {
  -ms-overflow-style: thin;
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;
}
.history-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.history-scroll::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 4px;
}

/* scroll bar end */

/* tinymce editor */

#tinymce,
.mce-content-body {
  @apply !bg-black;
}
